import { Box, Flex, NumberInput, Stack, Tooltip } from '@mantine/core'

import IconRow from '~/components/buyer/service-details/IconRow'
import TopIcons from '~/components/buyer/service-details/TopIcons'
import { PackageName, PackageType } from '~/components/seller/service-setup/PackageFields'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { SvgIconType } from '~/components/shared/image/SvgIcon'
import { Typography } from '~/components/shared/text/Typography'
import { SellerService, ServiceTier, ServiceType } from '~/endpoints/model'
// import { useVariant } from '~/experiments/ExperimentsProvider'
import { useScreenSize } from '~/hooks'

const PackageTab = ({
  name,
  onClick,
  active,
  disabled,
}: {
  name: PackageName
  onClick: () => void
  active?: boolean
  disabled?: boolean
}) => {
  const { isDesktopScreen } = useScreenSize()

  return (
    <Flex
      h={64}
      miw={isDesktopScreen ? 150 : undefined}
      p={10}
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderBottom: active ? '3px solid rgba(0, 0, 0, 0.80)' : undefined,
        cursor: disabled ? 'default' : 'pointer',
        flex: 1,
        opacity: disabled ? 0.3 : 1,
      }}
      align="center"
      justify="center"
      bg={active ? 'appWhite.0' : 'appPaper.0'}
      color={active ? 'appBlack.0' : 'appPlaceholder.0'}
      onClick={disabled ? undefined : onClick}
    >
      <Typography variant="body1">{name}</Typography>
    </Flex>
  )
}

export const getServicePackageDescription = (
  serviceType: ServiceType,
  packageType: PackageType
) => {
  switch (serviceType) {
    case ServiceType.consultation:
      return 'Get personalized travel advice and have any questions you about your trip answered.'
    case ServiceType.itinerary:
      return packageType === 'base'
        ? 'Get a high-level, custom itinerary outline with a basic daily plan.'
        : packageType === 'standard'
          ? 'Get a detailed custom itinerary with in-depth daily plans.'
          : 'Get a detailed custom itinerary with in-depth daily plans. Plus, hotels & restaurants booked for you.'
    default:
      return 'Get prepared for your trip with a custom list of recommendations curated to your preferences.'
  }
}

export const getServicePackageBottomIcons = (
  serviceType: ServiceType,
  packageType: PackageType
) => {
  const { CHECK_ICON, CLOSE_ICON } = SvgIconType
  switch (serviceType) {
    case ServiceType.consultation:
      return (
        <>
          <IconRow
            icon={CHECK_ICON}
            label="1:1 Live video call"
          />
          <IconRow
            icon={CHECK_ICON}
            label="Tailored advice"
          />
        </>
      )
    case ServiceType.itinerary:
      return (
        <>
          <IconRow
            icon={CHECK_ICON}
            label="High-level itinerary outline"
          />
          <IconRow
            icon={CHECK_ICON}
            label="Curated list of bookable hotels or Airbnbs"
          />
          <IconRow
            icon={CHECK_ICON}
            label="1 round of revisions for itinerary outline"
          />
          <IconRow
            icon={CHECK_ICON}
            label="Custom digital map + app"
          />
          <IconRow
            icon={packageType === 'base' ? CLOSE_ICON : CHECK_ICON}
            label="Detailed custom itinerary"
          />
          <IconRow
            icon={packageType === 'base' ? CLOSE_ICON : CHECK_ICON}
            label="1 round of revisions for detailed itinerary"
          />
          <IconRow
            icon={packageType === 'premium' ? CHECK_ICON : CLOSE_ICON}
            label="Hotel and restaurant booking service"
          />
        </>
      )
    default:
      return (
        <>
          <IconRow
            icon={CHECK_ICON}
            label="Personalized recommendations"
          />
          <IconRow
            icon={CHECK_ICON}
            label="Custom interactive map + app"
          />
        </>
      )
  }
}

const TabContent = ({
  packageType,
  serviceType,
  serviceTier,
  daysAmount,
  onChangeDaysAmount,
  scrollToPackages,
  onContinue,
}: {
  onContinue: () => void
  scrollToPackages: () => void
  daysAmount: number
  onChangeDaysAmount: (amount: number) => void
  serviceTier: ServiceTier
  serviceType: ServiceType
  packageType: PackageType
}) => {
  const { isMobileScreen } = useScreenSize()
  const isItinerary = serviceType === ServiceType.itinerary
  // const buttonVariant = useVariant('service-page-cta-text-1')

  // const buttonLabel = buttonVariant.value === 'continue' ? 'Continue →' :
  //                     buttonVariant.value === 'book_now' ? 'Book Now →' :
  //                     buttonVariant.value === 'book_instantly' ? 'Book Instantly →' :
  //                     buttonVariant.value === 'schedule_now' ? 'Schedule Now →' :
  //                     'Continue →';
  const buttonLabel = 'Continue →'

  return (
    <Stack
      spacing={24}
      miw={isMobileScreen ? undefined : 450}
      maw={isMobileScreen ? undefined : 450}
      sx={{
        padding: '32px 24px',
        alignSelf: 'stretch',
      }}
    >
      <Flex
        gap={8}
        align="end"
      >
        <Typography
          variant="title"
          sx={{ fontSize: '32px !important', marginBottom: -4 }}
        >
          ${(serviceTier.price as number) * daysAmount}
        </Typography>
        {isItinerary && (
          <Typography
            variant="body3"
            color="appPlaceholder.0"
          >
            (${serviceTier.price} per day planned)
          </Typography>
        )}
      </Flex>
      <Flex
        gap={12}
        direction="column"
      >
        <TopIcons
          serviceType={serviceType}
          serviceTier={serviceTier}
        />
      </Flex>

      <Typography variant="body2">
        {getServicePackageDescription(serviceType, packageType)}
      </Typography>

      {isItinerary && (
        <Flex
          gap={12}
          align="center"
        >
          <Typography variant="body3">Number of days to plan</Typography>
          <NumberInput
            value={daysAmount}
            sx={{ height: 40, width: 88 }}
            styles={{
              input: { borderRadius: 10, border: '1px solid rgba(0, 0, 0, 0.50)' },
              controlDown: { border: 'none', paddingRight: 13, paddingBottom: 5, borderRadius: 10 },
              controlUp: { border: 'none', paddingRight: 13, paddingTop: 5, borderRadius: 10 },
            }}
            onChange={value => onChangeDaysAmount(Number(value) || 1)}
            type="number"
          />
        </Flex>
      )}

      <Flex
        direction="column"
        gap={8}
      >
        {getServicePackageBottomIcons(serviceType, packageType)}
      </Flex>
      <Stack
        spacing={16}
        mt={44}
        align="center"
      >
        <Tooltip
          label="Thatch is no longer accepting purchases"
          position='bottom'
          withArrow
        >
          <ThatchButton
            w="100%"
            size="medium"
            label={buttonLabel}
            // onClick={() => {
            //   onContinue()
            // }}
            data-disabled
            sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
            onClick={(event) => event.preventDefault()}
          />
        </Tooltip>
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={scrollToPackages}
        >
          <Typography
            variant="button_medium"
            color="appPlaceholder.0"
          >
            Compare Packages
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

interface PackagesTabsSectionProps {
  service: SellerService
  packageType: PackageType
  daysAmount: number
  onChangeDaysAmount: (amount: number) => void
  setSelectedPackage: (type: PackageType) => void
  scrollToPackages: () => void
  onContinue: () => void
}

const PackagesTabsSection = ({
  service,
  packageType,
  daysAmount,
  onChangeDaysAmount,
  setSelectedPackage,
  scrollToPackages,
  onContinue,
}: PackagesTabsSectionProps) => {
  return (
    <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.25)', height: 'min-content' }}>
      <Flex>
        {service.base?.price && (
          <PackageTab
            name="Standard"
            active={packageType === 'base'}
            disabled={!service.base}
            onClick={() => setSelectedPackage('base')}
          />
        )}
        {service.standard?.price && (
          <PackageTab
            name="Premium"
            active={packageType === 'standard'}
            disabled={!service.standard}
            onClick={() => setSelectedPackage('standard')}
          />
        )}
        {service.premium?.price && (
          <PackageTab
            name="Luxe"
            active={packageType === 'premium'}
            disabled={!service.premium}
            onClick={() => setSelectedPackage('premium')}
          />
        )}
      </Flex>

      <TabContent
        daysAmount={daysAmount}
        onChangeDaysAmount={onChangeDaysAmount}
        packageType={packageType}
        serviceType={service.type}
        serviceTier={service[packageType] as ServiceTier}
        scrollToPackages={scrollToPackages}
        onContinue={onContinue}
      />
    </Box>
  )
}

export default PackagesTabsSection
