import { Flex, Sx, Tooltip } from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons'
import { useMemo } from 'react'

import { PackageType } from '~/components/seller/service-setup/PackageFields'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { Typography, Variant } from '~/components/shared/text/Typography'
import { ServiceTier, ServiceType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'
import { formatPrice } from '~/utils/helper'

// eslint-disable-next-line import/no-cycle
import { Title } from './ServiceDetails'

const TableCell = ({
  sx,
  label,
  bg = 'appWhite',
  isLeftLabel,
  isLastItem,
  variant,
}: {
  variant?: Variant
  label: string
  sx?: Sx
  bg?: string
  isLeftLabel?: boolean
  isLastItem?: boolean
  disabled?: boolean
}) => (
  <Flex
    justify={isLeftLabel ? 'start' : 'center'}
    align="center"
    bg={bg}
    sx={{
      height: 56,
      flex: 1,
      paddingLeft: isLeftLabel ? 16 : 0,
      borderRight: isLastItem ? 'none' : '1px solid rgba(0, 0, 0, 0.25)',
      ...sx,
      opacity: 1,
    }}
  >
    <Typography
      sx={{ width: '100%', textAlign: isLeftLabel ? 'start' : 'center' }}
      variant={variant}
      color={isLeftLabel ? 'appPlaceholder.0' : 'appBlack.0'}
    >
      {label}
    </Typography>
  </Flex>
)

type Row = { value?: any; isLeftLabel?: boolean }[]

type ComparePackagesProps = {
  packages: (ServiceTier & { type: PackageType })[]
  serviceType: ServiceType
  daysAmount: number
  onSelect: (type: PackageType) => void
}

const ComparePackages = ({ packages, serviceType, daysAmount, onSelect }: ComparePackagesProps) => {
  const { isMobileScreen } = useScreenSize()

  const data = useMemo(() => {
    const checkmarksRow = (validFromIndex = 0) =>
      packages.map((item, idx) => ({
        value:
          idx >= validFromIndex ? (
            <IconCheck
              width={24}
              height={24}
              key={`${idx}-${item.service}`}
            />
          ) : (
            <IconX
              width={24}
              height={24}
              opacity={0.25}
              key={`${idx}-${item.service}`}
            />
          ),
      }))

    const packageTitlesRow = [
      { value: 'Package', isLeftLabel: true },
      ...[
        ...(packages.find(pckg => pckg.type === 'base')?.price ? ['Standard'] : []),
        ...(packages.find(pckg => pckg.type === 'standard')?.price ? ['Premium'] : []),
        ...(packages.find(pckg => pckg.type === 'premium')?.price ? ['Luxe'] : []),
      ].map(type => ({ value: type })),
    ]
    const priceRow = [
      { value: '', isLeftLabel: true },
      ...packages.map(item => ({ value: item.price ? formatPrice(item.price) : '' })),
    ]
    const selectBtnRow = [
      { value: '', isLeftLabel: true },
      ...packages.map((pckg, idx) => ({
        value: (
          <Tooltip
            label="Thatch is no longer accepting purchases"
            position='bottom'
            withArrow
          >
            <ThatchButton
              key={`${pckg.service}-${idx}`}
              disabled={!pckg.price}
              // onClick={() => onSelect(pckg.type)}
              label={
                <Typography variant={isMobileScreen ? 'caption' : 'button_small'}>Select</Typography>
              }
              variant="filled"
              sx={{ width: '88%', marginBottom: 20, padding: 0, '&[data-disabled]': { pointerEvents: 'all' } }}
              data-disabled
              onClick={(event) => event.preventDefault()}
            />
          </Tooltip>
        ),
      })),
    ]

    const buildData = (rows: Row[], pricePerDay?: boolean) => [
      packageTitlesRow,
      ...rows,
      pricePerDay
        ? priceRow.map(item => ({ ...item, value: item.value ? `${item.value}/day` : '' }))
        : priceRow,
      selectBtnRow,
    ]

    switch (serviceType) {
      case 'recs':
        return buildData([
          [
            { value: 'Recs', isLeftLabel: true },
            ...packages.map(item => ({ value: `${item.recs} recs` })),
          ],
          [
            { value: 'Delivery', isLeftLabel: true },
            ...packages.map(item => ({ value: `${item.delivery} days` })),
          ],
          [{ value: 'Digital Map + App', isLeftLabel: true }, ...checkmarksRow()],
        ])
      case 'consultation':
        return buildData([
          [
            { value: 'Duration', isLeftLabel: true },
            ...packages.map(item => ({ value: `${item.duration} mins` })),
          ],
          [{ value: 'Live Video Call', isLeftLabel: true }, ...checkmarksRow()],
          [{ value: 'Tailored Advice', isLeftLabel: true }, ...checkmarksRow()],
        ])
      default:
        return buildData(
          [
            [
              { value: 'Delivery', isLeftLabel: true },
              ...packages.map(item => ({ value: item.delivery ? `${item.delivery} days` : '-' })),
            ],
            [{ value: 'Hotel / Airbnb Recs', isLeftLabel: true }, ...checkmarksRow()],
            [{ value: 'Digital Map + App', isLeftLabel: true }, ...checkmarksRow()],
            [{ value: 'Itinerary Outline', isLeftLabel: true }, ...checkmarksRow()],
            [{ value: 'Outline Revision', isLeftLabel: true }, ...checkmarksRow()],
            [{ value: 'Detailed Itinerary', isLeftLabel: true }, ...checkmarksRow(1)],
            [{ value: 'Detailed Revision', isLeftLabel: true }, ...checkmarksRow(1)],
            [{ value: 'Booking Service', isLeftLabel: true }, ...checkmarksRow(2)],
            [
              { value: 'Starting deposit', isLeftLabel: true },
              ...packages.map(item => ({
                value: formatPrice(((item.price as number) * daysAmount) / 2),
              })),
            ],
          ],
          true
        )
    }
  }, [daysAmount])
  const isTableCellDisabled = (idx: number) => {
    switch (idx) {
      case 1:
        return !packages.find(pckg => pckg.type === 'base')?.price
      case 2:
        return !packages.find(pckg => pckg.type === 'standard')?.price
      case 3:
        return !packages.find(pckg => pckg.type === 'premium')?.price
      default:
        return false
    }
  }

  return (
    <Flex
      gap={32}
      direction="column"
    >
      <Title
        value="Compare"
        italic="Packages"
      />

      <Flex
        direction="column"
        sx={{ border: '1px solid rgba(0, 0, 0, 0.25)' }}
      >
        {data.map((row, rowIdx) => (
          <Flex key={rowIdx}>
            {row.map(({ value, isLeftLabel }: any, cellIdx) => (
              <TableCell
                key={`${cellIdx}-${rowIdx}`}
                isLeftLabel={isLeftLabel}
                label={value}
                variant={!rowIdx && cellIdx ? 'body1' : 'body3'}
                disabled={isTableCellDisabled(cellIdx)}
                bg={
                  (rowIdx + 1) % 2 !== 0 || rowIdx === data.length - 1 ? 'appWhite.0' : 'appPaper.0'
                }
              />
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default ComparePackages
